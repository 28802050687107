/**
 * Import files here
 */
//import Example from "./lib/example-files";
import SmoothScroll from "smooth-scroll";
import Sticky from "sticky-js";

/**
 * The option can be detected by looking at the prefers-color-scheme media query.
 * It can be one of the following values:
 * light: User would like to see the page in the light mode
 * dark: User would like to see the page in the dark mode
 * no-preference: The system doesn't know about the user preferences
 * By checking against this media query value, we can determine if the user prefers the drak mode or not:
 */
const isDarkMode =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

/*
 * Detect mobile Browsers
 * Check if the browser supports the pointer:coarse media query:
 */
const isMobile = function () {
  const match = window.matchMedia("(pointer:coarse)");
  return match && match.matches;
};

(function () {
  const sticky = new Sticky(".sticky");
  const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 25,
    speedAsDuration: false,
  });
  const toggle = (function () {
    const selectors = document.querySelectorAll("[data-toggle]");

    selectors.forEach((selector) => {
      selector.addEventListener("click", (e) => {
        let id = e.target.getAttribute("data-toggle");
        const target = document.getElementById(id.replace("#", ""));

        selectors.forEach((s) => {
          var ariaExpanded = s.getAttribute("aria-expanded");
          s.setAttribute(
            "aria-expanded",
            ariaExpanded === "true" ? "false" : "true"
          );
        });

        if (target) {
          target.toggleAttribute("hidden");
        }
      });
    });
  })();
})();
